import { useTranslation } from 'react-i18next';
import './index.scss'
import { useEffect } from 'react';

export const FBMatchTypeTab = ({allMatchTimeTypes, selected, onChange, match, selectedOddsType}) => {
    const { i18n } = useTranslation();
    const lang = i18n.language;
    const tabList = allMatchTimeTypes.filter(i => {
    return (match?.foPools?.filter(pool => i.filterType.includes(pool.oddsType)).length > 0) || i.key == 'ALL'
    });
    const showTab=tabList.length >= 3;
    useEffect(()=>{
        const showCurTab = getCurrentTypePools(allMatchTimeTypes, selected, match?.foPools, selectedOddsType).length > 0
        if(!showTab || !showCurTab){
            onChange('ALL')
        }
    },[match.foPools, selectedOddsType, selected])
    return showTab ? <div className='FBMatchTypeTabContainer' id='FBMatchType_Section'>
        {
        allMatchTimeTypes.sort((a, b)=> a.sequence - b.sequence).map((Item) => {
            let showCurTab = match?.foPools?.filter(pool => Item.filterType.includes(pool.oddsType) ) || []
            if(showCurTab.length > 0 || Item.key == 'ALL'){
                return <div id={`FBMatchType_${Item.key}`} className={`FBMatchTypeTab ${selected === Item?.key ? 'active' : '' }`} onClick={() => onChange(Item?.key)}>{Item?.[lang]}</div>
            }
            return null
        })
        }
    </div> : null
}

export const getCurrentTypePools = (allMatchTimeTypes, type, foPools, selectedOddsType) =>{
    const currentTypePools = allMatchTimeTypes.find(i=> i.key == type)?.filterType?.filter(betType => !selectedOddsType || selectedOddsType?.includes(betType)) || []
    return foPools?.filter(pool => currentTypePools.includes(pool.oddsType) ) || []
}
