import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { handleClickHelpLink, handleClickChanceTableLink } from './Common';
import Instructions from './Instructions';
import { MarksixContext } from '../../Home/MarksixPage';
import { isSnowballDraw } from './Common';
import { useWindowSize } from '../../Common/CommonHooks';
import { scrollTo } from '../../Common/GlobalFunc';

const BallPanelTable = ({ onChangeBall, size = 50, curBallList = [], type, selectedData = [], clear, clearPartBall }) => {
    const context = useContext(MarksixContext);
    const isSB = isSnowballDraw(context.nextDraw);
    const { isMobileSize } = useWindowSize();

    const [selectedBall, setSelectedBall] = useState([]);
    const [selectedBankerBall, setSelectedBankerBall] = useState([]);
    const { t, i18n } = useTranslation();
    const [betTypeSelect, setBetTypeSelect] = useState([
        { type: 'banker', name1: 'LB_M6_BANKERLABEL', disabled: false, checked: true },
        { type: 'leg', name1: 'LB_M6_LEGLABEL', disabled: false, checked: false }
    ]);

    const scSize = document.documentElement.clientWidth;

    useEffect(() => {
        if (curBallList?.numList?.length === size) {
            // single true
            setSelectedBall([]);
        } else {
            setSelectedBall(curBallList.numList);
            setSelectedBankerBall(curBallList.bankerList || []);
        }
    }, [curBallList, size]);

    const handleClickBall = (num) => {
        let ballList = [];
        if (num === 50) {
            const allBall = Array.from(new Array(50).keys()).slice(1);
            setSelectedBall(allBall);
            onChangeBall(allBall);
            return;
        }
        if (curBallList.numList.includes(num)) {
            ballList = curBallList.numList.filter((i) => i !== num);
        } else if (curBallList.numList.length < size) {
            ballList = curBallList.numList.concat(num);
        }
        if (curBallList.numList.length >= size) {
            onChangeBall(curBallList.numList, num);
            return;
        }
        const newBallList = ballList.sort((a, b) => a - b);
        setSelectedBall(newBallList);
        onChangeBall(newBallList);

        // scrollTo(0, document.querySelector('.ball-panel-table-container').offsetTop - 135 + 1)
    };

    const handleClickBankerBall = (num) => {
        let curLabelType = betTypeSelect.filter((x) => x.checked)[0].type;
        const { numList, bankerList } = curBallList;
        const curSeletedList = [...new Set([...numList, ...bankerList])];
        const labelList = curLabelType === 'banker' ? 'bankerList' : 'numList';
        let ballList = [];
        let newBallList = { ...curBallList };
        if (num === 50) {
            const allBall = Array.from(new Array(50).keys()).slice(1);
            ballList = allBall.filter((x) => !bankerList.includes(x));
            curLabelType = 'leg';
            newBallList.numList = ballList.sort((a, b) => a - b);
        } else {
            if (curSeletedList.includes(num)) {
                if (bankerList.includes(num)) {
                    newBallList.bankerList = newBallList.bankerList.filter((i) => i !== num);
                    if (curLabelType !== 'banker') {
                        newBallList.numList = [...newBallList.numList, num].sort((a, b) => a - b);
                    }
                }
                if (numList.includes(num)) {
                    newBallList.numList = newBallList.numList.filter((i) => i !== num);
                    if (curLabelType === 'banker' && newBallList.bankerList.length < 5) {
                        newBallList.bankerList = [...newBallList.bankerList, num].sort((a, b) => a - b);
                    }
                }
            } else {
                if (curLabelType === 'banker' && curBallList[labelList].length >= 5) {
                    alert(t('LB_M6_BANKERMAXNUM'));
                    return false;
                }
                newBallList[labelList] = [...curBallList[labelList], num].sort((a, b) => a - b);
            }
        }
        onChangeBall(newBallList, null, 0);

        // scrollTo(0, document.querySelector('.ball-panel-table-container').offsetTop - 135 + 1)
    };

    const BankerLabel = () => {
        return (
            <div className="banker-selected-area">
                {betTypeSelect.map((item) => {
                    return (
                        <div
                            className={`banker-selected-text ${item.checked ? 'active' : ''}`}
                            onClick={() => labelOnchange(item)}
                            key={item.name1}
                        >
                            {t(item.name1)}
                        </div>
                    );
                })}
            </div>
        );
    };

    const labelOnchange = (data) => {
        let items = betTypeSelect;
        if (data.type === items.filter((x) => x.checked)[0].type) {
            return;
        }
        items.forEach((item) => {
            item.checked = !item.checked;
        });
        setBetTypeSelect((prev) => {
            return [...items];
        });
    };

    const tableProps = {
        type,
        handleClickBankerBall,
        handleClickBall,
        selectedBall,
        isMobileSize,
        selectedBankerBall,
        betTypeSelect
    }


    return (
        <div className="ball-panel-table-container" id="ball-panel-table-container">
            <div className="marsix-chance-help-m">
                {type !== 'single' && (
                    <div className="df marsix-chance-help" onClick={handleClickChanceTableLink}>
                        <i className="icon-table" />
                        <div>{t(`LB_M6_HEADER_CHANCE_TABLE_${type.toUpperCase()}`)}</div>
                    </div>
                )}
            </div>
            <Instructions selectedData={selectedData} clear={clear} tableType={type} clearPartBall={clearPartBall} />

            {type === 'banker' && <BankerLabel />}
            <Table {...tableProps}/>
        </div>
    );
};

export default BallPanelTable;


const Row = ({ rowIndex, colorList, type, handleClickBankerBall,handleClickBall,selectedBall,selectedBankerBall,betTypeSelect }) => {

    const { isMobileSize } = useWindowSize();
    const { t, i18n } = useTranslation();
    const row = [];
    const rowSize = !isMobileSize ? 10 : 7;
    const ballSize = !isMobileSize ? 'l' : 'm';
    // const rowSize = 10
    // const ballSize = 'l';
    const curClickBallFn = type === 'banker' ? handleClickBankerBall : handleClickBall;
    const showField = type === 'multiple' || (type==='banker' && betTypeSelect.filter((x) => x.checked)[0].type=="leg");
    for (let i = 1; i <= rowSize; i++) {
        const num = i + rowIndex * rowSize;
        const color = colorList[num - 1];
        if (num === 50 && showField ) {
            row.push(
                <div className="table-cell" key={num} onClick={() => {curClickBallFn(num)}}>
                    {selectedBall.length < 49 ? (
                        <div className="yellow-ball cp">{t('LB_M6_BALLPANEL_ALL')}</div>
                    ) : (
                        <div className="table-cell-ball cp">
                            <img className={`ballsize-${ballSize}`}
                                src={
                                    require(`../../../info/marksix/info/images/marksix-F_${i18n.language}.svg`)
                                }
                                alt={num}
                            />
                        </div>
                    )}
                </div>
            );
        } else if (num >= 50) {
            row.push(<div key={num} />);
        } else {
            row.push(
                <div className="table-cell" onClick={() => {curClickBallFn(num)}} key={num}>
                    {!selectedBall.includes(num) && !selectedBankerBall.includes(num) ? (
                        <div className={`${color}-ball cp`}>{num}</div>
                    ) : selectedBankerBall.includes(num) ? (
                        <div className="table-cell-ball cp">
                            <img className={`ballsize-${ballSize}`}
                                src={require(`../../../info/marksix/info/images/marksix-gold-${num}.svg`)}
                                alt={num}
                            />
                        </div>
                    ) : (
                        <div className="table-cell-ball cp">
                            <img className={`ballsize-${ballSize}`}
                                src={require(`../../../info/marksix/info/images/marksix-${num}.svg`)}
                                alt={num}
                            />
                        </div>
                    )}
                </div>
            );
        }
    }
    return <div className="table-row">{row}</div>;
};

const Table = (props) => {
    const table = [];
    const rowSize = !props.isMobileSize ? 5 : props.type === 'single' ? 7 : 8;
    let key = 1;
    const colorList = [];
    for (let rowIndex = 0; rowIndex < 5; rowIndex++) {
        for (let i = 1; i <= 10; i++) {
            const red = [1, 2, 7, 8].includes(i - rowIndex);
            const blue = [3, 4, -2, -3, 9, 10].includes(i - rowIndex);
            const color = red ? 'red' : blue ? 'blue' : 'green';
            colorList.push(color);
        }
    }
    for (let i = 0; i < rowSize; i++) {
        table.push(<Row key={key++}
            rowIndex={i} colorList={colorList} {...props}/>);
    }

    return <div className="ball-panel-table" id="ball-panel-table">{table}</div>;
};
