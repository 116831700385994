window.FBMatchController = new AbortController()
window.fbMatchreFetchTimer = null
const refetchTime = 5000
window.RCMeetingFetchTimer = null
window.RCPoolsFetchTimer = null

const APIFetch = (para) => {

    return fetch(para.url,{
        method: 'POST',
        signal: para.signal,
        body: JSON.stringify(para.body),
        headers:{
            "Content-Type": "application/json",
            "FE": "1"
        }
    }).then(res => {
        return res.json()
    }).then(results => {
        if(results.errors && !para.disableErrorMsg){
            console.error(results.errors)
            throw new Error(results.errors?.[0]?.message)
        }
        return results
    })
}

export const Query = ({query, variables={}, controller,url, disableErrorMsg=false},) => {
    url = url || window.globalConfig.GRAPHQL_URL
    return APIFetch({
        url,
        body:{
            query,
            variables
       },
       signal: controller?.signal,
       disableErrorMsg
    })
}

export const abortFBMatch = () => {
    clearTimeout(window.fbMatchreFetchTimer)
    window.FBMatchController.abort()
    window.FBMatchController = new AbortController()

}

export const apiErrorCallback = (error, callbackFunc, option={}) =>{
    let { timer, disableRefetch } = option
    if(error.name == 'AbortError' || disableRefetch) return
    console.error(error)
    timer = timer || window.fbMatchreFetchTimer
    timer = setTimeout(()=>{
        callbackFunc()
    }, (window.globalConfig.API_REFETCH_TIME || 12000))
}
