export const callWAReq = () => {
    //if (!isLogon() && isSSOSignedIn()) {
    //    sendCheckSSOSignInStatusRequest(postCallWAReq, postCallWAReq);
    //} else {
    postCallWAReq();
    //}
};

export const WATrackerTrackClickEvent = (eventId) => {
    if (typeof window.WATracker !== 'undefined' && window.WATracker) {
        try {
            clearWAParam();
            window.WATracker.trackClickEvent(eventId);
        } catch (err) {
            console.error('WATrackerTrackClickEvent', err);
        }
    }
};

function postCallWAReq() {
    try {
        if (!sessionStorage.getItem('__tmpRef')) {
            if (document.referrer?.length > 0) {
                sessionStorage.setItem('__tmpRef', document.referrer);
            }
        }
        clearWAParam();

        var pvar = {
            content: '0',
            server: 'secure-sg',
            page_url: document.location,
            referrer: sessionStorage.getItem('__tmpRef')
        };

        WATrackerTrackPageView(pvar);
        sessionStorage.setItem('__tmpRef', document.location);
    } catch (e) {}
}

const clearWAParam = () => {
    try {
        window.s.pageName = undefined;
        window.s.prop1 = undefined;
        window.s.prop10 = undefined;
        window.s.eVar10 = undefined;
        window.s.eVar31 = undefined;

        var webIDHashed = (window.s.eVar31 = getWebIDHashed());
        if (typeof window.DataLayer != 'undefined') {
            window.DataLayer = {
                cuno: webIDHashed
            };
        }

        if (sessionStorage.getItem('__tmpRef')) {
            window.s.prop6 = sessionStorage.getItem('__tmpRef');
        }
        window.customerSegmentWA = getCustomerSegment();
    } catch (e) {}
};

const WATrackerTrackPageView = (eventId) => {
    if (typeof window.WATracker !== 'undefined' && window.WATracker) {
        try {
            window.WATracker.trackPageView(eventId);
        } catch (err) {
            console.error('WATrackerTrackPageView', err);
        }
    }
};

export const getWebIDHashed = () => {
    //hash id if login
    if (sessionStorage.getItem('customerHash') && sessionStorage.getItem('customerHash') != 'undefined') {
        return sessionStorage.getItem('customerHash');
    } else if (sessionStorage.getItem('lite_account_id') && sessionStorage.getItem('lite_account_id') != 'undefined') {
        return sessionStorage.getItem('lite_account_id');
    } else {
        return '';
    }
};

export const getCustomerSegment = () => {
    //customer segment if login
    if (sessionStorage.getItem('customerSegment') && sessionStorage.getItem('customerSegment') != 'undefined') {
        return sessionStorage.getItem('customerSegment');
    } else {
        return '';
    }
};
