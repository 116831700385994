
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import App from './App';
import './css.js'
import i18n from '../../i18n.js';
import { I18nextProvider } from 'react-i18next';
import reportWebVitals from '../../reportWebVitals.js';
import { ApolloClient, HttpLink, InMemoryCache, ApolloProvider, ApolloLink } from "@apollo/client";
import Loading from '../../components/Common/ui-components/Loading/index.js';

sessionStorage.removeItem("__tmpRef"); //clear WA temp

const link = new HttpLink({ uri: window.globalConfig.GRAPHQL_URL, headers: { FE: "1" }, useGETForQueries: false });
const linkStub = new HttpLink({ uri: window.globalConfig.STUB_GRAPHQL_URL, headers: { FE: "1" }, useGETForQueries: false });
const linkSiteCore = new HttpLink({
  uri: window.globalConfig.SITECORE_GRAPHQL_URL,
  headers: { sc_apikey: window.globalConfig.SITECORE_APIKEY },
  useGETForQueries: false
});

// const client = new ApolloClient({
//     link: link,
//     cache: new InMemoryCache({ addTypename: false })
// });

const client = new ApolloClient({
  link: ApolloLink.split(
      operation => operation.getContext().clientName === 'sitecore',
      linkSiteCore, //if above
      ApolloLink.split(
        operation => operation.getContext().clientName === 'wcas',
        linkStub, //if above
        link
      )
  ),
  cache: new InMemoryCache({ addTypename: false })
})

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <Suspense fallback={<Loading/>}>
        <ApolloProvider client={client}>
          <App />
        </ApolloProvider>
      </Suspense>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
